exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-index-js": () => import("./../../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-contacto-index-js": () => import("./../../../src/pages/contacto/index.js" /* webpackChunkName: "component---src-pages-contacto-index-js" */),
  "component---src-pages-faq-index-js": () => import("./../../../src/pages/FAQ/index.js" /* webpackChunkName: "component---src-pages-faq-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-aviso-index-js": () => import("./../../../src/pages/legal/aviso/index.js" /* webpackChunkName: "component---src-pages-legal-aviso-index-js" */),
  "component---src-pages-legal-canal-etico-index-js": () => import("./../../../src/pages/legal/canal-etico/index.js" /* webpackChunkName: "component---src-pages-legal-canal-etico-index-js" */),
  "component---src-pages-legal-cookies-index-js": () => import("./../../../src/pages/legal/cookies/index.js" /* webpackChunkName: "component---src-pages-legal-cookies-index-js" */),
  "component---src-pages-legal-privacidad-index-js": () => import("./../../../src/pages/legal/privacidad/index.js" /* webpackChunkName: "component---src-pages-legal-privacidad-index-js" */),
  "component---src-pages-one-burger-laundry-carta-index-js": () => import("./../../../src/pages/one_burger/laundry/carta/index.js" /* webpackChunkName: "component---src-pages-one-burger-laundry-carta-index-js" */),
  "component---src-pages-one-burger-laundry-index-js": () => import("./../../../src/pages/one_burger/laundry/index.js" /* webpackChunkName: "component---src-pages-one-burger-laundry-index-js" */),
  "component---src-pages-one-burger-motel-carta-index-js": () => import("./../../../src/pages/one_burger/motel/carta/index.js" /* webpackChunkName: "component---src-pages-one-burger-motel-carta-index-js" */),
  "component---src-pages-one-burger-motel-index-js": () => import("./../../../src/pages/one_burger/motel/index.js" /* webpackChunkName: "component---src-pages-one-burger-motel-index-js" */),
  "component---src-pages-reserva-cumpleanos-index-js": () => import("./../../../src/pages/reserva-cumpleaños/index.js" /* webpackChunkName: "component---src-pages-reserva-cumpleanos-index-js" */),
  "component---src-pages-reserva-index-js": () => import("./../../../src/pages/reserva/index.js" /* webpackChunkName: "component---src-pages-reserva-index-js" */),
  "component---src-pages-restaurante-alicante-tanzania-carta-index-js": () => import("./../../../src/pages/restaurante/alicante/tanzania/carta/index.js" /* webpackChunkName: "component---src-pages-restaurante-alicante-tanzania-carta-index-js" */),
  "component---src-pages-restaurante-alicante-tanzania-index-js": () => import("./../../../src/pages/restaurante/alicante/tanzania/index.js" /* webpackChunkName: "component---src-pages-restaurante-alicante-tanzania-index-js" */),
  "component---src-pages-restaurante-sevilla-paris-carta-index-js": () => import("./../../../src/pages/restaurante/sevilla/paris/carta/index.js" /* webpackChunkName: "component---src-pages-restaurante-sevilla-paris-carta-index-js" */),
  "component---src-pages-restaurante-sevilla-paris-index-js": () => import("./../../../src/pages/restaurante/sevilla/paris/index.js" /* webpackChunkName: "component---src-pages-restaurante-sevilla-paris-index-js" */),
  "component---src-pages-restaurante-valencia-bali-carta-index-js": () => import("./../../../src/pages/restaurante/valencia/bali/carta/index.js" /* webpackChunkName: "component---src-pages-restaurante-valencia-bali-carta-index-js" */),
  "component---src-pages-restaurante-valencia-bali-index-js": () => import("./../../../src/pages/restaurante/valencia/bali/index.js" /* webpackChunkName: "component---src-pages-restaurante-valencia-bali-index-js" */),
  "component---src-pages-restaurante-valencia-casa-carta-index-js": () => import("./../../../src/pages/restaurante/valencia/casa/carta/index.js" /* webpackChunkName: "component---src-pages-restaurante-valencia-casa-carta-index-js" */),
  "component---src-pages-restaurante-valencia-casa-index-js": () => import("./../../../src/pages/restaurante/valencia/casa/index.js" /* webpackChunkName: "component---src-pages-restaurante-valencia-casa-index-js" */),
  "component---src-pages-restaurante-valencia-kioto-carta-index-js": () => import("./../../../src/pages/restaurante/valencia/kioto/carta/index.js" /* webpackChunkName: "component---src-pages-restaurante-valencia-kioto-carta-index-js" */),
  "component---src-pages-restaurante-valencia-kioto-index-js": () => import("./../../../src/pages/restaurante/valencia/kioto/index.js" /* webpackChunkName: "component---src-pages-restaurante-valencia-kioto-index-js" */),
  "component---src-pages-restaurante-valencia-manhattan-carta-index-js": () => import("./../../../src/pages/restaurante/valencia/manhattan/carta/index.js" /* webpackChunkName: "component---src-pages-restaurante-valencia-manhattan-carta-index-js" */),
  "component---src-pages-restaurante-valencia-manhattan-index-js": () => import("./../../../src/pages/restaurante/valencia/manhattan/index.js" /* webpackChunkName: "component---src-pages-restaurante-valencia-manhattan-index-js" */),
  "component---src-pages-restaurante-zaragoza-nuevazelanda-carta-index-js": () => import("./../../../src/pages/restaurante/zaragoza/nuevazelanda/carta/index.js" /* webpackChunkName: "component---src-pages-restaurante-zaragoza-nuevazelanda-carta-index-js" */),
  "component---src-pages-restaurante-zaragoza-nuevazelanda-index-js": () => import("./../../../src/pages/restaurante/zaragoza/nuevazelanda/index.js" /* webpackChunkName: "component---src-pages-restaurante-zaragoza-nuevazelanda-index-js" */),
  "component---src-pages-restaurante-zaragoza-nuevazelanda-reserva-codigo-index-js": () => import("./../../../src/pages/restaurante/zaragoza/nuevazelanda/reserva-codigo/index.js" /* webpackChunkName: "component---src-pages-restaurante-zaragoza-nuevazelanda-reserva-codigo-index-js" */),
  "component---src-pages-ubicaciones-index-js": () => import("./../../../src/pages/ubicaciones/index.js" /* webpackChunkName: "component---src-pages-ubicaciones-index-js" */),
  "component---src-pages-unete-index-js": () => import("./../../../src/pages/unete/index.js" /* webpackChunkName: "component---src-pages-unete-index-js" */)
}

